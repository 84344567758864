import React from "react";
import { Routes, Route } from "react-router-dom";
import SharedLayout from "layouts/shared";

const App = () => {
  return (
    <Routes>
      {/* <Route path="/login" element={<Login />} />
      <Route path="auth/*" element={<AuthLayout />} /> */}
      <Route path="/*" element={<SharedLayout />} />
    </Routes>
  );
};

export default App;
// import "./index.css";
// import { useState, useEffect } from "react";
// import { Auth } from "@supabase/auth-ui-react";
// import { ThemeSupa } from "@supabase/auth-ui-shared";
// import { supabase } from "utils/supabase";

// export default function App() {
//   const [session, setSession] = useState(null);


//   useEffect(() => {
//     console.log('Attempting to fetch session...', localStorage.getItem('session'));
//     supabase.auth.getSession().then(({ data: session, error }) => {
//       if (error) {
//         console.error('Error fetching session:', error.message);
//         return;
//       }
      
//       console.log('Session fetched successfully:', session);
//       setSession(session);
//       // navigate('/admin'); // Redirect to the main app page
//     });
    
//     const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
//       console.log('Auth state changed:', session);
//       setSession(session);
//     });
    
//     return () => subscription.unsubscribe();
//   }, []);
//   if (!session) {
//     return (
//       <div
//         style={{
//           width: "100vw",
//           height: "100vh",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div>
//           <Auth
//             supabaseClient={supabase}
//             appearance={{ theme: ThemeSupa }}
//             providers={["google", "facebook", "github"]}
//           />
//         </div>

//       </div>
//     );
//   } else {
//     return (
//       <div>
//         <div>Logged in!</div>
//         <button onClick={() => supabase.auth.signOut()}>Sign out</button>
//       </div>
//     );
//   }
// }
