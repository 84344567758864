import { FormLabel } from "@chakra-ui/react";
import Checkbox from "components/checkbox";

const CheckField = (props) => {
  const { id, label, className, value, onChange } = props;
  return (
    <div className={className}>
      <Checkbox id={id} checked={value} value={value} onChange={onChange} />
      <FormLabel
        htmlFor={id}
        className="!text-sm !mb-0 ml-3 text-navy-700 dark:text-white"
      >
        {label}
      </FormLabel>
    </div>
  );
};

export default CheckField;
