import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { useSelector } from "react-redux";
import { Collapse, List, ListItem, Divider } from "@chakra-ui/react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

export function SidebarLinks(props) {
  let location = useLocation();
  const { employee } = useSelector((state) => state.auth);
  const { routes } = props;

  const activeRoute = (routeName) => location.pathname.includes(routeName);

  const [isOpenDashboard, setIsOpenDashboard] = useState(false);
  const [isOpenLeaveTracking, setIsOpenLeaveTracking] = useState(true);
  const [isOpenPerformanceManagement, setIsOpenPerformanceManagement] = useState(false);
  const [isOpenPayrollManagement, setIsOpenPayrollManagement] = useState(false);
  const [isOpenOrganization, setIsOpenOrganization] = useState(false);

  const handleClick = (setIsOpen) => {
    setIsOpen((prevState) => !prevState);
  };

  const createLinks = (routes) => {
    let userRoutes = [];
    if (employee?.employee_role_name === "hr-manager") {
      userRoutes = routes;
    } else if (employee?.employee_role_name === "reporting-manager") {
      userRoutes = routes.filter(
        (route) => route.layout === "/" || route.layout === "/manage"
      );
    } else if (employee?.employee_role_name === "employee") {
      userRoutes = routes.filter((route) => route.layout === "/");
    }

    const groupedRoutes = {
      "Dashboard": userRoutes.filter(route =>
        ["My Info", "News & Updates", "Calendar"].includes(route.name)
      ),
      "Leave Tracking": userRoutes.filter(route =>
        ["My Leaves", "Leaves Summary", "Calendar View", "Manage Leaves", "Manage Employees", "Add Holidays"].includes(route.name)
      ),
      "Performance Management": userRoutes.filter(route =>
        ["Self Assessments", "Performance Rating"].includes(route.name)
      ),
      "Payroll Management": userRoutes.filter(route =>
        ["Salary Slips", "Salary History"].includes(route.name)
      ),
      "Organization": userRoutes.filter(route =>
        ["Departments", "Org Chart", "Directory"].includes(route.name)
      )
    };
    const filteredRoutes = Object.keys(groupedRoutes).reduce((acc, key) => {
      if (groupedRoutes[key].length > 0) {
        acc[key] = groupedRoutes[key];
      }
      return acc;
    }, {}); // filter the ones that have at least one route object
    return (
      <List>
        {Object.keys(filteredRoutes).map((category, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <div
                className={`flex justify-between items-center cursor-pointer px-8 py-2 ${
                  (category === "Dashboard" && isOpenDashboard) ||
                  (category === "Leave Tracking" && isOpenLeaveTracking) ||
                  (category === "Performance Management" && isOpenPerformanceManagement) ||
                  (category === "Payroll Management" && isOpenPayrollManagement) ||
                  (category === "Organization" && isOpenOrganization)
                    ? "text-black"
                    : "text-gray-600"
                }`}
                onClick={() => {
                  switch (category) {
                    case "Dashboard":
                      handleClick(setIsOpenDashboard);
                      break;
                    case "Leave Tracking":
                      handleClick(setIsOpenLeaveTracking);
                      break;
                    case "Performance Management":
                      handleClick(setIsOpenPerformanceManagement);
                      break;
                    case "Payroll Management":
                      handleClick(setIsOpenPayrollManagement);
                      break;
                    case "Organization":
                      handleClick(setIsOpenOrganization);
                      break;
                    default:
                      break;
                  }
                }}
              >
                <span className="font-bold">{category}</span>
                <span>
                  {(category === "Dashboard" && isOpenDashboard) ||
                  (category === "Leave Tracking" && isOpenLeaveTracking) ||
                  (category === "Performance Management" && isOpenPerformanceManagement) ||
                  (category === "Payroll Management" && isOpenPayrollManagement) ||
                  (category === "Organization" && isOpenOrganization) ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronRight />
                  )}
                </span>
              </div>
              <Collapse
                in={
                  (category === "Dashboard" && isOpenDashboard) ||
                  (category === "Leave Tracking" && isOpenLeaveTracking) ||
                  (category === "Performance Management" && isOpenPerformanceManagement) ||
                  (category === "Payroll Management" && isOpenPayrollManagement) ||
                  (category === "Organization" && isOpenOrganization)
                }
              >
                {groupedRoutes[category].map((route, index) => (
                  <Link
                    key={index}
                    to={
                      (route.layout === "/" ? "" : route.layout) +
                      "/" +
                      route.path
                    }
                  >
                    <div className="relative mb-3 flex hover:cursor-pointer">
                      <li
                        className="my-[3px] flex cursor-pointer items-center px-8"
                        key={index}
                      >
                        <span
                          className={`${
                            activeRoute(route.path)
                              ? "font-bold text-brand-500 dark:text-white"
                              : "font-medium text-gray-600"
                          }`}
                        >
                          {route.icon ? route.icon : <DashIcon />}
                        </span>
                        <p
                          className={`leading-1 ml-4 flex ${
                            activeRoute(route.path)
                              ? "font-bold text-navy-700 dark:text-white"
                              : "font-medium text-gray-600"
                          }`}
                        >
                          {route.name}
                        </p>
                      </li>
                      {activeRoute(route.path) ? (
                        <div className="absolute left-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                      ) : null}
                    </div>
                  </Link>
                ))}
              </Collapse>
            </ListItem>
            {index < Object.keys(groupedRoutes).length - 1 && (
              <Divider my={4} />
            )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return createLinks(routes);
}

export default SidebarLinks;
