// Custom components
import React from "react";
import { FormLabel } from '@chakra-ui/react'

const InputField = (props) => {
  const { label, id, extra, type, required, placeholder, variant, state, disabled, value, onChange, readonly } =
    props;

  return (
    <div className={`${extra}`}>
      <FormLabel
        htmlFor={id}
        className={`!text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "font-bold"
          }`}
      >
        {label}
      </FormLabel>
      <input
        disabled={disabled}
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        readOnly={readonly}
        className={`mt-2 flex h-12 w-full items-center justify-between rounded-xl border bg-white/0 p-3 text-sm outline-none ${disabled === true
          ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)] dark:text-white"
          : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
      />
    </div>
  );
}

export default InputField;
