import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  holidays: [],
  page: 1,
  isFormOpen: false,
  selectedHoliday: null,
  isDeleteConfirmationOpen: false,
  mode: "Add", // Default mode is set to "Add"
  search: '',
  pagination: {
    total: 0,
  },
  isLoading: false,
  sortBy: [{ id: 'start_date', desc: false }]
};

const addHolidayReducers = createSlice({
  name: 'addHoliday',
  initialState,
  reducers: {
    setHolidays: (state, action) => {
      state.holidays = action.payload;
    },
    setPage: (state, action) => {
      state.page = Number(action.payload)
    },
    setPaginationData: (state, action) => {
      state.pagination = action.payload
    },
    openAddForm: (state) => {
      state.isFormOpen = true;
      state.mode = "Add"; // Set mode to "Add" when opening the form
    },
    openEditForm: (state, action) => {
      state.isFormOpen = true;
      state.mode = "Edit"; // Set mode to "Edit" when opening the form
      state.selectedHoliday = action.payload;
    },
    closeForm: (state) => {
      state.isFormOpen = false;
      state.selectedHoliday = null;
    },
    setSelectedHoliday: (state, action) => {
      state.selectedHoliday = action.payload;
    },
    openDeleteConfirmation: (state, action) => {
      state.isFormOpen = false;
      state.isDeleteConfirmationOpen = true;
      state.selectedHoliday = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.isDeleteConfirmationOpen = false;
      state.selectedHoliday = null;
    },
    // Added actions for adding, updating, and removing holiday
    addHoliday: (state, action) => {
      state.holidays.push(action.payload);
      state.pagination = {
        ...state.pagination, total: state.pagination.total + 1
      };
      state.isFormOpen = false;
    },
    updateHoliday: (state, action) => {
      const index = state.holidays.findIndex(holiday => holiday.public_holiday_id === action.payload.public_holiday_id);
      if (index !== -1) {
        state.holidays[index] = action.payload;
      }
      state.isFormOpen = false;
      state.selectedHoliday = null;
    },
    removeHoliday: (state, action) => {
      state.holidays = state.holidays.filter(holiday => holiday.public_holiday_id !== action.payload);
      state.pagination.total -= 1;
      state.isDeleteConfirmationOpen = false;
      state.selectedHoliday = null;
    },
    setSearch: (state, action) => {
      if (action.payload && state.search !== action.payload) state.page = 1;  // reset page on new search
      state.search = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    }
  },
});

export const {
  setHolidays,
  openAddForm,
  openEditForm,
  closeForm,
  setSelectedHoliday,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  addHoliday,
  updateHoliday,
  removeHoliday,
  setSearch,
  setPage,
  setPaginationData,
  setIsLoading,
  setSort
} = addHolidayReducers.actions;

export default addHolidayReducers.reducer;
