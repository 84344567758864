import * as XLSX from "xlsx";

export const exportToExcel = (data, filename) => {
    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Get the header row (first row)
    const headerRow = Object.keys(data[0]);
    // Apply cell formatting for the header row
    headerRow.forEach((col, index) => {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
        worksheet[cellRef].font = { bold: true };
    });
    
    // Set column widths
    const columnWidths = headerRow.map(() => ({ wch: 15 })); // Default width of 15 for each column
    worksheet['!cols'] = columnWidths;

    // Save the workbook as Excel file
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};
