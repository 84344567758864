import { createStandaloneToast } from '@chakra-ui/react'

const customToast = () => {

const { toast } = createStandaloneToast()
    const showToast = (options) => {
      toast({
          position: 'top',
          duration: 4000,
          isClosable: true,
          variant: 'top-accent',
          ...options,
        });
      };

  return {
    showToast
  };
};

export default customToast;