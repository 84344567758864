import { FormLabel } from "@chakra-ui/react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = (props) => {
  const { 
    startDate, setStartDate, excludeDates = [], placeholder, limitPastTo30Days, label, id, variant,
    includeWeekends = false
  } = props;

  // Function to get the date 30 days from now
  const getThirtyDaysPastFromNow = () => {
    const today = new Date();
    const thirtyDaysFromNow = new Date(today.setDate(today.getDate() - 30));
    return thirtyDaysFromNow;
  };

  // Get today's date
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to start of the day

  return (
    <div>
      <FormLabel
        htmlFor={id}
        className={`!text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "font-bold"
          }`}
      >
        {label}
      </FormLabel>
      <ReactDatePicker
        selected={startDate}
        wrapperClassName="custom-datepicker-style dark:text-white w-[100%]"
        placeholderText={placeholder}
        onChange={(date) => {
          setStartDate(date);
        }}
        startDate={startDate}
        excludeDates={excludeDates ?? []}
        filterDate={(date) => {
          // Disable weekends (Saturday and Sunday)
          if (includeWeekends) return true;
          else return date.getDay() !== 0 && date.getDay() !== 6;
        }}
        minDate={limitPastTo30Days ? getThirtyDaysPastFromNow() : undefined} // Disable dates more than 30 days older
        showMonthDropdown={!limitPastTo30Days}
        useShortMonthInDropdown
        showYearDropdown={!limitPastTo30Days}
        showIcon
        dateFormat="dd/MM/yyyy"
        dayClassName={(date) => (date.getDay() === 0 || date.getDay() === 6 ? "weekend" : null)}
      />
    </div>
  );
};

export default DatePicker;
