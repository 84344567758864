import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employees: [],
  managers: [],
  employeeRoles: [],
  departments: [],
  page: 1,
  isFormOpen: false,
  selectedEmployee: null,
  isDeleteConfirmationOpen: false,
  mode: "Add",
  search: '',
  isLoading: false,
  pagination: {
    total: 0,
    previous: null,
    next: null
  },
  sortBy: [{ id: 'employee_name', desc: false }]
};

const manageEmployeesReducers = createSlice({
  name: 'manageEmployees',
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setManagers: (state, action) => {
      state.managers = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setEmployeeRoles: (state, action) => {
      state.employeeRoles = action.payload;
    },
    setPage: (state, action) => {
      state.page = Number(action.payload)
    },
    setPaginationData: (state, action) => {
      state.pagination = action.payload
    },
    openAddForm: (state) => {
      state.isFormOpen = true;
      state.mode = "Add"
    },
    openEditForm: (state, action) => {
      state.isFormOpen = true;
      state.mode = "Edit"
      state.selectedEmployee = action.payload;
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    closeForm: (state) => {
      state.isFormOpen = false;
      state.selectedEmployee = null;
    },
    setSelectedEmployee: (state, action) => {
      state.selectedEmployee = action.payload;
    },
    openDeleteConfirmation: (state, action) => {
      state.isDeleteConfirmationOpen = true;
      state.selectedEmployee = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.isDeleteConfirmationOpen = false;
      state.selectedEmployee = null;
    },
    addEmployee: (state, action) => {
      state.employees.push(action.payload);
      state.pagination = {
        ...state.pagination, total: state.pagination.total + 1
      };
      state.isFormOpen = false;
    },
    updateEmployee: (state, action) => {
      const index = state.employees.findIndex(empl => empl.id === action.payload.id);
      if (index !== -1) {
        state.employees[index] = action.payload;
      }
      state.isFormOpen = false;
      state.selectedEmployee = null;
    },
    removeEmployee: (state, action) => {
      state.employees = state.employees.filter(empl => empl.id !== action.payload);
      state.isDeleteConfirmationOpen = false;
      state.selectedEmployee = null;
    },
    setSearch: (state, action) => {
      if (action.payload && state.search !== action.payload) state.page = 1;  // reset page on new search
      state.search = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
  },
});

export const {
  setEmployees,
  setManagers,
  setEmployeeRoles,
  openAddForm,
  openEditForm,
  closeForm,
  setSelectedEmployee,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  addEmployee,
  updateEmployee,
  removeEmployee,
  setSearch,
  setPage,
  setPaginationData,
  setIsLoading,
  setDepartments,
  setSort
} = manageEmployeesReducers.actions;

export default manageEmployeesReducers.reducer;
