import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  calendarData: [],
  holidays: [],
  isLoading: false
};

const leavesCalendarReducers = createSlice({
  name: 'leavesCalendar',
  initialState,
  reducers: {
    setHolidaysList: (state, action) => {
      state.holidays = action.payload
    },
    setCalendarEvents: (state, action) => {
      state.calendarData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    }
  },
});

export const {
  setCalendarEvents,
  setHolidaysList,
  setIsLoading
} = leavesCalendarReducers.actions;

export default leavesCalendarReducers.reducer;
