import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leaveRequests: [],
  leaveTypes: [],
  page: 1,
  isFormOpen: false,
  selectedLeave: null,
  isDeleteConfirmationOpen: false,
  mode: "Add",
  searchDate: null,
  employeeLeaveQuota: [],
  holidays: [],
  isLoading: false,
  pagination: {
    total: 0,
    previous: null,
    next: null
  },
  employees: [],
  notifyemployee: [],
  notifymessage: '',
  sortBy: [{ id: 'end_date', desc: true }],
  reportingLine: []
};

const myLeavesReducers = createSlice({
  name: 'myLeaves',
  initialState,
  reducers: {
    setLeaveRequests: (state, action) => {
      state.leaveRequests = action.payload;
    },
    setLeaveTypes: (state, action) => {
      state.leaveTypes = action.payload;
    },
    setPage: (state, action) => {
      state.page = Number(action.payload)
    },
    setPaginationData: (state, action) => {
      state.pagination = action.payload
    },
    openAddForm: (state) => {
      state.isFormOpen = true;
      state.mode = "Add"
    },
    openEditForm: (state, action) => {
      state.isFormOpen = true;
      state.mode = "Edit"
      state.selectedLeave = action.payload;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    closeForm: (state) => {
      state.isFormOpen = false;
      state.selectedLeave = null;
    },
    setSelectedLeave: (state, action) => {
      state.selectedLeave = action.payload;
    },
    openDeleteConfirmation: (state, action) => {
      state.isFormOpen = false;
      state.isDeleteConfirmationOpen = true;
      state.selectedLeave = action.payload;
    },
    closeDeleteConfirmation: (state) => {
      state.isDeleteConfirmationOpen = false;
      state.selectedLeave = null;
    },
    setEmployeeLeaveQuota: (state, action) => {
      state.employeeLeaveQuota = action.payload
    },
    setSearchDate: (state, action) => {
      if (action.payload && state.searchDate !== action.payload) state.page = 1;  // reset page on new search
      state.searchDate = action.payload;
    },
    setHolidaysList: (state, action) => {
      state.holidays = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setEmployees: (state, action) => {
      state.employees = action.payload
    },
    setNotifyEmployees: (state, action) => {
      state.notifyemployee = action.payload
    },
    setNotifyMessage: (state, action) => {
      state.notifymessage = action.payload
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
    setReportingLineInfo: (state, action) => {
      state.reportingLine = action.payload
    }
  },
});

export const {
  setLeaveRequests,
  setLeaveTypes,
  openAddForm,
  openEditForm,
  closeForm,
  setSelectedLeave,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  setPage,
  setPaginationData,
  setEmployeeLeaveQuota,
  setSearchDate,
  setHolidaysList,
  setIsLoading,
  setEmployees,
  setNotifyEmployees,
  setNotifyMessage,
  setSort,
  setReportingLineInfo
} = myLeavesReducers.actions;

export default myLeavesReducers.reducer;
