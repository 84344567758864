import ReactDatePicker from "react-datepicker";
import { MdCalendarToday } from "react-icons/md";

const DateFilter = (props) => {
  const { searchDate, setSearchDate} = props
  return (
    <div className="flex mt-8 mb-3 ml-3 h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-700 dark:text-white w-[250px]">
      <p className="pl-3 pr-2 text-xl">
        <MdCalendarToday className="h-4 w-4 text-gray-400 dark:text-white" />
      </p>
      <ReactDatePicker
        selected={searchDate}
        wrapperClassName="search-datepicker-style w-[100%]"
        placeholderText="Search by date.."
        onChange={(date) => {
          setSearchDate(date);
        }}
        startDate={searchDate}
        autoFocus={false}
        filterDate={date => {
          // Disable weekends (Saturday and Sunday)
          return date.getDay() !== 0 && date.getDay() !== 6;
        }}
        isClearable
        clearButtonClassName="after:!bg-brand-500 mr-2"
        clearButtonTitle="Clear"
        dateFormat="dd/MM/yyyy"
        dayClassName={(date) => (date.getDay() === 0 || date.getDay() === 6 ? "weekend" : null)}
        calendarClassName="dark"
      />
    </div>
  );
}

export default DateFilter