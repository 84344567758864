import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Card } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchAllLeaveRequests } from '../manageLeaves/api/manageLeaves';
import { setCalendarEvents, setHolidaysList, setIsLoading } from './reducers/leavesCalendarReducers';
import { getCalendarHolidays } from './api/leavesCalendar';
import Loader from 'components/loader';

const localizer = momentLocalizer(moment)

const CalendarView = () => {
  const {
    calendarData, holidays, isLoading
  } = useSelector((state) => state.leavesCalendar);
  const [agendaView, setAgendaView] = useState(false);
  const [updateCells, setUpdateCells] = useState(0);
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.layout);

  useEffect(() => {
    const updateTimeCells = () => {
      const timeCells = document.querySelectorAll('.rbc-agenda-time-cell span.rbc-continues-after');
      // set half day in agenda view time slot
      timeCells.forEach(cell => {
        if (cell.textContent.trim().includes('12:00 AM')) {
          cell.textContent = 'half day';
        }
      });
    };

    if (agendaView && updateCells > 0) updateTimeCells();
  }, [agendaView, updateCells]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    fetchAllLeaveRequests().then(({ data }) => {
      if (data && data?.length > 0) dispatch(setCalendarEvents(
        data.filter(lv => lv.leave_type_name !== 'maternity').map(ev => ({
          start: ev.start_date,
          end: ev.end_date ? ev.end_date : ev.start_date,
          title: `${ev.employee_name} - ${ev.leave_type_name === "special" ? "" : (ev.is_half_day ? `half day ${ev.half_leave_type ? `(${ev.half_leave_type})` : ""}` : ev.leave_type_name)} leave`,
          allDay: !ev.is_half_day,
          isSecondHalf : ev.half_leave_type && ev.half_leave_type === "second-half"
        }))))
    })
    getCalendarHolidays().then((data) => {
      dispatch(setHolidaysList(data.map((ev => ({
        start: ev.start_date,
        end: ev.end_date ? ev.end_date : ev.start_date,
        title: ev.holiday_name,
        allDay: true,
        color: 'green'
      })))))
      dispatch(setIsLoading(false));
    })
  }, [dispatch])

  return (
    <>
      {isLoading ? <Loader /> : (
        <div className="my-6 mx-4">
          <Card className="bg-white dark:!bg-navy-800 p-8 dark:shadow-none dark:text-lightPrimary ">
            <Calendar
              localizer={localizer}
              events={[
                ...calendarData.map(ev => ({
                  ...ev,
                  start: moment(ev.start, 'YYYY-MM-DD').toDate(),
                  end: moment(ev.end, 'YYYY-MM-DD').add(1, "days").toDate()
                })),
                ...holidays.map(ev => ({
                  ...ev,
                  start: moment(ev.start, 'YYYY-MM-DD').toDate(),
                  end: moment(ev.end, 'YYYY-MM-DD').add(1, "days").toDate()
                }))
              ]}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 550 }}
              dayPropGetter={(date) => {
                const day = new Date(date).getDay();
                const today = new Date();
                if (date.toDateString() === today.toDateString() && darkMode) return {
                  style: {
                    backgroundColor: "black",
                  }
                }
                if (day === 0 || day === 6) {
                  return {
                    style: {
                      backgroundColor: darkMode ? '#303030' : '#e6e6e6',
                    }
                  }
                }
              }}
              formats={{
                monthHeaderFormat: 'MMMM YYYY',
                agendaHeaderFormat: (range) => `${moment(range.start).format('DD-MMMM-YY')} to ${moment(range.end).format('DD-MMMM-YY')}`,
                agendaDateFormat: 'ddd DD-MMM-YY'
              }}
              views={['month', 'work_week', 'day', 'agenda']}
              eventPropGetter={(ev) => {
                let bgColor = ev.color ? ev.color : (darkMode ? '#F28820' : '#f5ac63');
                bgColor = ev.allDay ? bgColor : '#2dabe9';
                let styleObj = { backgroundColor: bgColor };
                if (!ev.allDay) { styleObj = { ...styleObj, width: "50%" } }
                if (ev.isSecondHalf) { styleObj = { ...styleObj, marginLeft: "auto" } }
                return { style: styleObj }
              }}
              onNavigate={() => { if (agendaView) setUpdateCells(updateCells + 1) }}
              onView={(v) => {
                if (v === 'agenda') { setAgendaView(true); setUpdateCells(1) }
                else { setAgendaView(false); setUpdateCells(0) }
              }}
              className={`${darkMode ? "dark" : "light"}-custom-calendar`}
            />
          </Card>
        </div>
      )}
    </>
  )
}

export default CalendarView