import React, { useState, useEffect } from "react";
import { FormControl, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { addNewEmployee, editEmployee } from "../api/manageEmployees";
import { EMAIL_CHECK_REGEX } from "variables/regexVars";
import customToast from 'utils/toastUtils';
import { Card } from "@chakra-ui/react";
import { MdMinimize } from "react-icons/md";
import { closeForm } from "../reducers/manageEmployeesReducers";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import DatePicker from "components/fields/DatePicker";
import SwitchField from "components/fields/SwitchField";

const toast = customToast();

const validateEmail = (employee_email) => EMAIL_CHECK_REGEX.test(employee_email ?? "")

const EmployeeForm = (props) => {
  const { mode, data } = props
  const {
    search, page, employeeRoles, managers, departments, sortBy
  } = useSelector((state) => state.manageEmployees);
  const dispatch = useDispatch();

  const initialFormState = {
    employee_id: data?.employee_id ?? '',
    employee_name: data?.employee_name ?? '',
    employee_email: data?.employee_email ?? '',
    date_of_joining: data?.date_of_joining ? new Date(data?.date_of_joining) : null,
    employee_role_id: data?.employee_role_id ?? '',
    manager_id: data?.manager_id ?? null,
    employee_gender: data?.employee_gender ?? '',
    department_id: data?.department_id ?? null,
    employee_status: data?.employee_status ?? true,
    last_date: data?.last_date ? new Date(data?.last_date) : null,
  };

  const [formState, setFormState] = useState(initialFormState);

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    setFormState({
      employee_id: data?.employee_id ?? '',
      employee_name: data?.employee_name ?? '',
      employee_email: data?.employee_email ?? '',
      date_of_joining: data?.date_of_joining ? new Date(data?.date_of_joining) : null,
      employee_role_id: data?.employee_role_id ?? '',
      manager_id: data?.manager_id ?? null,
      employee_gender: data?.employee_gender ?? '',
      department_id: data?.department_id ?? null,
      employee_status: data?.employee_status ?? true,
      last_date: data?.last_date ? new Date(data?.last_date) : null,
    });
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = mode === "Add" ? { ...formState } : formState;

    if (!validateEmail(formData.employee_email)) {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please enter a valid employee_email',
        status: 'error',
      })
      return;
    }
      if (mode === "Edit" && formData?.employee_id) {
        dispatch(editEmployee(formData.employee_id, formData.manager_id, formData, search, page, sortBy));
      } else {
        delete formData.employee_id;
        delete formData.manager_id;
        dispatch(addNewEmployee(formData, formState.manager_id, search, page, sortBy));
        setFormState(initialFormState);
      }
  };

  return (
    <div className="my-6 ml-4">
      <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none">
        <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
          <div className="text-xl mt-8 mb-3 ml-1 font-bold text-navy-700 dark:text-white">
            {mode === "Edit" ? "Edit Employee" : "New Employee"}
          </div>
          <button
            onClick={() => dispatch(closeForm())}
            className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
          >
            <MdMinimize size={35} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="mx-4">
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              <InputField
                type='text'
                label='Employee Name'
                placeholder="Enter employee name"
                value={formState.employee_name}
                id='employee_name'
                onChange={(e) => handleInputChange('employee_name', e.target.value)}
                required
              />
            </FormControl>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              <InputField
                type='email'
                label='Employee Email'
                id='employee_email'
                placeholder="Enter employee email"
                value={formState.employee_email}
                onChange={(e) => handleInputChange('employee_email', e.target.value)}
                required
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              <SelectField
                id='employee_gender'
                label='Employee Gender'
                placeholder="Select Employee Gender"
                required
                value={formState.employee_gender}
                onChange={(e) => handleInputChange("employee_gender", e.target.value)}
                options={["male", "female"].map((item) => ({ id: item, value: item }))}
              />
            </FormControl>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              <DatePicker
                placeholder="Enter employee DOJ"
                id="date_of_joining"
                label="Date of Joining"
                startDate={formState.date_of_joining}
                limitPastTo30Days={false}
                setStartDate={(e) => handleInputChange('date_of_joining', e)}
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl isRequired className="flex flex-col mx-1 mb-3">
              <SelectField
                id='employee_role_id'
                label='Employee Role'
                placeholder="Select Employee Role"
                required
                value={formState.employee_role_id}
                onChange={(e) => handleInputChange("employee_role_id", e.target.value)}
                options={employeeRoles.map((role) => ({ id: role.employee_role_id, value: role.rolename }))}
              />
            </FormControl>
            <FormControl className="flex flex-col mx-1 mb-3">
              <SelectField
                id='department_id'
                label='Select Department'
                placeholder="Select Employee Department"
                value={formState.department_id}
                capitalize={false}
                onChange={(e) => handleInputChange("department_id", e.target.value)}
                options={departments.map((dep) => ({ id: dep.department_id, value: dep.department_label }))}
              />
            </FormControl>
          </Flex>
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl className="flex flex-col mx-1 mb-3 max-w-[49%]">
              <SelectField
                id='manager_id'
                label='Select Manager'
                placeholder="Select Employee Manager"
                value={formState.manager_id}
                capitalize={false}
                onChange={(e) => handleInputChange("manager_id", e.target.value)}
                options={managers.map((emp) => ({ id: emp.employee_id, value: emp.employee_email }))}
              />
            </FormControl>
            <FormControl className="flex flex-col mx-1 mb-3 max-w-[49%]">
              <SwitchField
                id='employee_status'
                label='Employee Status'
                value={formState.employee_status}
                onChange={(e) => handleInputChange("employee_status", e.target.checked)}
                showLabel
              />
            </FormControl>
          </Flex>

          {mode === "Edit" && (
            <Flex justify="space-between" align="center" mb={1}>
              <FormControl className="flex flex-col mx-1 mb-3 max-w-[49%]">
                <DatePicker
                  placeholder="Enter last working date"
                  id="last_date"
                  label="Last Working Date"
                  startDate={formState.last_date}
                  limitPastTo30Days={false}
                  setStartDate={(e) => handleInputChange('last_date', e)}
                />
              </FormControl>
            </Flex>
          )}

          <div className="flex justify-end align-center mb-3">
            <button
              type='submit'
              className="linear flex items-center justify-center mt-6 mb-3 w-40 rounded-xl bg-brand-500 py-[7px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
            >
              Save
            </button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default EmployeeForm