import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leavesQuota: [],
  search: '',
  page: 1,
  isLoading: false,
  pagination: {
    total: 0,
    previous: null,
    next: null
  },
  sortBy: [{ id: 'employee_name', desc: false }],
  alphaFilter: 'A'
};

const leavesQuotaSlice = createSlice({
  name: 'leavesQuota',
  initialState,
  reducers: {
    setLeavesQuota: (state, action) => {
      state.leavesQuota = action.payload;
    },
    setPage: (state, action) => {
      state.page = Number(action.payload)
    },
    setPaginationData: (state, action) => {
      state.pagination = action.payload
    },
    setSearch: (state, action) => {
      if (action.payload && state.search !== action.payload) state.page = 1;  // reset page on new search
      state.search = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
    setAlphaFilter: (state, action) => {
      state.alphaFilter = action.payload;
    },
  },
});

export const { 
  setLeavesQuota, setPage, setPaginationData, setSearch, setIsLoading, setSort,
  setAlphaFilter
 } = leavesQuotaSlice.actions;

export default leavesQuotaSlice.reducer;
