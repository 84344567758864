import axios from "axios";
import customToast from "./toastUtils";

const toast = customToast();
export const sendEmail = async (email, name, subject, body) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apikey': process.env.REACT_APP_SUPABASE_KEY,
        'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`
      },
      data: JSON.stringify({name, email, subject, body})
    };

    // Make the request using Axios
    const response = await axios(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/send-email`, requestOptions);

    if (response.status === 200) {
      console.log(`--------- Email sent to ${email}`)
    }
  } catch (error) {
    console.error('Error sending email:', error); // Handle network error
    toast.showToast({
      title: 'Email not sent',
      description: `Could not send email notification to ${email}`,
      status: 'warning',
    })
  }
};

