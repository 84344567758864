import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewHoliday, editHoliday } from "../api/manageAddHoliday";
import customToast from 'utils/toastUtils';
import { Card, FormControl } from "@chakra-ui/react";
import { MdMinimize } from "react-icons/md";
import DateRangePicker from "components/fields/DateRangePicker";
import InputField from "components/fields/InputField";
import { closeForm } from "../reducers/manageAddHolidayReducers";
import { isFromDateGreaterThanTo } from "utils/dateUtils";

const toast = customToast();

const AddHolidayForm = (props) => {
  const { mode, data } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { search, page, sortBy } = useSelector((state) => state.addHoliday);

  const initialFormState = {
    public_holiday_id: mode === "Edit" ? data?.public_holiday_id : null, // Include public_holiday_id for editing
    holiday_name: data?.holiday_name ?? '',
    start_date: data?.start_date ? new Date(data?.start_date) : null,
    end_date: data?.end_date ? new Date(data?.end_date) : null,
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    setFormState({
      public_holiday_id: mode === "Edit" ? data?.public_holiday_id : null, // Include public_holiday_id for editing
      holiday_name: data?.holiday_name ?? '',
      start_date: data?.start_date ? new Date(data?.start_date) : null,
      end_date: data?.end_date ? new Date(data?.end_date) : null,
    });
  }, [data, mode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState.holiday_name === "") {
      toast.showToast({
        title: 'Invalid Data',
        description: 'Please enter a valid holiday name',
        status: 'error',
      });
      return;
    }
    if (!formState.start_date || isFromDateGreaterThanTo(formState.start_date, formState.end_date)) {
      toast.showToast({
        title: 'Invalid Date Range',
        description: 'Please enter a valid date range for the holiday',
        status: 'error',
      });
      return;
    }

    if (mode === "Edit") {
      setLoading(true);
      dispatch(editHoliday(formState, search, page, sortBy)).then(() => setLoading(false));
      dispatch(closeForm());
    } else {
      const newData = { ...formState };
      delete newData.public_holiday_id;
      setLoading(true);
      dispatch(addNewHoliday(newData, search, page, sortBy)).then(() => {
        setLoading(false);
        dispatch(closeForm());
      });
    }
  };

  return (
    <div className="my-6 ml-4">
      <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none">
        <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
          <div className="text-xl mt-8 mb-3 ml-1 font-bold text-navy-700 dark:text-white">
            {mode === "Edit" ? "Edit Holiday" : "New Holiday"}
          </div>
          <button
            onClick={() => dispatch(closeForm())}
            className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
          >
            <MdMinimize size={35} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="mx-4">
          <FormControl isRequired className="flex flex-col mx-1 mb-3">
            <InputField
              label='Holiday Name'
              id="name"
              value={formState.holiday_name}
              onChange={(e) => handleInputChange("holiday_name", e.target.value)}
              placeholder="Enter a name for holiday"
              type="text"
              required
            />
          </FormControl>
          <FormControl isRequired className="flex flex-col mx-1 mb-3">
            <DateRangePicker
              startDate={formState.start_date}
              setStartDate={(val) => handleInputChange('start_date', val)}
              endDate={formState.end_date}
              setEndDate={(val) => handleInputChange('end_date', val)}
              placeholder="Enter holiday date range"
              id="holiday_date_range"
              label="Holiday Date Range"
              limitPastTo30Days={true}
              required
            />
          </FormControl>
          <div className="flex justify-end align-center mb-3">
            <button
              disabled={loading}
              type='submit'
              className="linear flex items-center justify-center mt-6 mb-3 w-40 rounded-xl bg-brand-500 py-[7px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:bg-gray-600"
            >
              Save
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default AddHolidayForm;
