export const EMAIL_CHECK_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
export const URL_CHECK_REGEX = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
export const anchorTagRegex = /<a\s.*?>(.*?)<\/a>/g;
export const liTagRegex = /<li>(.*?)<\/li>/g;
export const olTagRegex = /<ol>(.*?)<\/ol>/g;
export const ulTagRegex = /<ul>(.*?)<\/ul>/g;

export const applyTagStyles = (content) => {
  const modifiedContent = content
    .replace(anchorTagRegex, (match) => {
      // Preserve the href attribute while applying styles to the anchor tag
      const modifiedMatch = match.replace(/<a([^>]*)>(.*?)<\/a>/g, (fullMatch, attributes, innerContent) => {
        return `<span style="color: blue; text-decoration: underline;"><a${attributes}>${innerContent}</a></span>`;
      });
      return modifiedMatch;
    })
    .replace(olTagRegex, (match, p1) => {
      const olItems = p1.split('<li>').filter(Boolean);
      const numberedItems = olItems.map((item, index) => `<li style="color: black;">${index + 1}. ${item}</li>`);
      return `<ol>${numberedItems.join('')}</ol>`;
    })
    .replace(ulTagRegex, (match, p1) => {
      const ulItems = p1.split('<li>').filter(Boolean);
      const bulletedItems = ulItems.map((item) => `<li style="color: black;">• ${item}</li>`);
      return `<ul>${bulletedItems.join('')}</ul>`;
    })
    .replace(liTagRegex, (match, p1) => p1); // No additional styling for <li> tags

  return modifiedContent;
};