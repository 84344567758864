import Card from "components/card";

const LeaveQuotaWidget = ({ icon, title, availed, total, hideTotal }) => {
  const availedValue = Number(availed) || 0;
  const totalValue = Number(total) || 0;

  let quotaText;
  if (hideTotal) {
    quotaText = availedValue;
  } else if (totalValue === 0) {
    quotaText = "N/A";
  } else {
    quotaText = `${availedValue} / ${totalValue}`;
  }

  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-brand-600">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 mx-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600 capitalize">{title}</p>
        <div className="flex justify-between items-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white" title="availed / total">
            {quotaText}
          </h4>
        </div>
      </div>
    </Card>
  );
};

export default LeaveQuotaWidget;